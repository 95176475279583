import { isFunction } from "lodash";
import store from '@/vuex';
import { emailNotificationCache } from "@/vuex/modules/mails/mail";
import util from 'util';
import ActionCable from 'actioncable';
import LocalStorageConstant from "@/common/constants/local-storage.constant";
import Vue from "vue";
import api from "@/fetch/api";

const setupWebsocket = () => {
  let WEBSOCKET_ADDRESS = handleBaseAddress();
  
  if(!WEBSOCKET_ADDRESS)
    return;

  if (!Vue.prototype.$ActionCable) {
    Vue.prototype.$ActionCable = ActionCable.createConsumer(WEBSOCKET_ADDRESS);
  }

  // Monkey-Patching Connection object
  const connection = Vue.prototype.$ActionCable.connection;
  const originalOpen = connection.open;
  connection.open = function () {
    const returnValue = originalOpen.apply(this, arguments);
    this.webSocket && this.webSocket.addEventListener("close", (event) => {
      if (event.code === 1006 || event.code === 4001) { // Websocket closed abnormally or unauthorized
        console.warn("Websocket Closed:", event.code);
        store.state.online && api.online_check(); // Call online_check to set new token in Set-Cookie header for WS reconnection.
      }
    });
    return returnValue;
  };

  if (Vue.prototype.$ActionCable.subscriptions.subscriptions.length === 0) {
    const sub = Vue.prototype.$ActionCable.subscriptions.create({
        channel: 'NotificationsChannel'
      }, {
        connected: function () {
          console.log('connected: ', new Date());
          sub && sub.perform("get_server_datetime");
          if (!isFunction(this.connected.mail)) return
          this.connected.mail()
        },
        received: function (data) {
          switch (data.type) {
            case "ServerDateTime":
              configureServerDateTime(data.message);
              break;
            case "CopyFolder":
              store.dispatch("loadChildFolders", {
                parentId: data.message.parent_id,
                category: data.message.personal ? "personal" : "share",
                copy_folder_name: data.message.name,
                copy_done: true,
                no_cache: true
              });
              break;
            case "Email":
              if (!isFunction(this.received.mail)) return;
              data.message.forEach((item) => {
                emailNotificationCache.put(item.id, structuredClone(item));
                this.received.mail(item);
                util.sendMessageToSw({
                  message: "saveEmail",
                  item,
                });
              });
              break;
            case "MarkAsRead":
              if (!isFunction(this.received.markAsRead)) return;
              const ids = data.message[0] ? data.message[0].ids : []
              this.received.markAsRead(ids);
              break;
            case "Tag":
              if (!isFunction(this.received.tag)) return;
              this.received.tag(data);
              break;
            case "Notification":
            case "Permission":
              if (!isFunction(this.received.header)) return;
              this.received.header(data);
              break;
            case "Comment":
              if (!isFunction(this.received.comment)) return;
              this.received.comment(data);
              break;
            case "LineStatus":
              if (!isFunction(this.received.lineStatus)) return;
              data.message && this.received.lineStatus(data.message);
              break;
            case "UpdateUnreadCount":
              if (!isFunction(this.received.unreadCount)) return;
              this.received.unreadCount(data.message);
              break;
            case "Assignment":
              if (!isFunction(this.received.assignment)) return;
              data.message && this.received.assignment(data.message);
              break;
            case "UpdateCloseStatus":
              if (!isFunction(this.received.mailClosed)) return;
              data.message &&
                data.message.length > 0 &&
                data.message.map((item) => this.received.mailClosed(item));
              break;
            case "EmailFolder":
              if (!isFunction(this.received.emailFolder)) return;
              data.message && this.received.emailFolder(data.message);
              break;
            case "CompanyTag":
              if (!isFunction(this.received.companyTag)) return;
              data.message && this.received.companyTag(data.message);
              break;
            case "UpdateRoleDisableMention":
              if(!data.message) return;
              store.commit("SET_ROLE_DISABLE_MENTION", data.message)
              break;
            case "CalendarEvents":
              if(!data.message) return;
              store.dispatch("setCalendarEvents", data.message)
              break;
            case "ForceLogout":
              if (!isFunction(this.received.forceLogout) || !data.message)
                return
              this.received.forceLogout(data.message)
              break;
            case "TrigonalEmailDraft":
              if (!isFunction(this.received.trigonalEmailDraft) || !data.message)
                return
              this.received.trigonalEmailDraft(data.message)
              break;
            case "TrigonalFilingSuccess":
              if (!isFunction(this.received.trigonalFilingSuccess) || !data.message)
                return
              this.received.trigonalFilingSuccess(data.message)
              break
            case "TrigonalPreviewEmail":
              if (!isFunction(this.received.trigonalPreviewEmail) || !data.message)
                return
              this.received.trigonalPreviewEmail(data.message)
              break
            case "EmailStatusUpdate":
              if (!isFunction(this.received.emailStatusUpdate) || !data.message)
                return
              this.received.emailStatusUpdate(data.message)
              break
            case "Folders":
              if (!isFunction(this.received.userFolderUpdate) || !data.message)
                return
              this.received.userFolderUpdate(data.message)
              break
            default:
              break;
          }
        },
        disconnected: function () {
          console.log('GG: ', new Date());
          if (!isFunction(this.disconnected.mail)) return
          this.disconnected.mail()
        }
      },
    );
  }
}

const handleBaseAddress = () => {
  const savedBaseUrl = localStorage.getItem(LocalStorageConstant.BASE_URL);
  
  if(!savedBaseUrl)
    return;

  return `wss://endpoint.${savedBaseUrl}/cable`;
}

const configureServerDateTime = (timeStp) => {
  window.timeGap = +new Date(timeStp) - Date.now();
  localStorage.setItem("timeGap", JSON.stringify(window.timeGap));
}

export {setupWebsocket};