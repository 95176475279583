<template>
  <div class="recursive-item" v-if="isShowFolder">
    <div class="content">
      <template v-if="item[hasChildrenProp]">
        <Spin v-if="loading" class="loading-icon">
          <div class="loader">
            <svg-sprite name="mt_circle" @click.native.stop />
          </div>
        </Spin>
        <svg-sprite
          v-else
          class="expand-icon"
          :width="18"
          :height="18"
          :name="selfExpand ? 'mt_arrow_down' : 'mt_arrow_right'"
          @click.native.stop="onExpand"
        />
      </template>
      <slot :item="item" :isChild="isChild" :index="index" :atTop="atTop" :atBottom="atBottom" />
    </div>
    <div
      v-if="selfExpand && item[hasChildrenProp]"
      class="children"
    >
      <RecursiveItem
        v-for="(child, index) in item[childrenProp]"
        :key="child[keyProp]"
        :id="child[keyProp]"
        :parent-id="item[keyProp]"
        :has-child="item[hasChildrenProp]"
        :item="child"
        :isChild="true"
        :category="category"
        :index="index"
        :atTop="index==0"
        :atBottom="index == item[childrenProp].length - 1"
        :hasChildrenProp="hasChildrenProp"
        :childrenProp="childrenProp"
        :keyProp="keyProp"
        :expandingItems="expandingItems"
        :expandMethod="expandMethod"
        :itemToExpand="itemToExpand"
        :itemToSearch="itemToSearch"
        :isShowHidden="isShowHidden"
        @searchDone="onSearchDone"
      >
        <!-- bind scoped slots to the netable-item component -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </RecursiveItem>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RecursiveItem',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    isChild: Boolean,
    index: Number,
    atTop: Boolean,
    atBottom: Boolean,
    category: String,
    hasChildrenProp: {
      type: String,
      default: 'has_children'
    },
    childrenProp: {
      type: String,
      default: 'children'
    },
    keyProp: {
      type: String,
      default: 'id'
    },
    itemToSearch: {
      type: Object,
      default: () => {}
    },
    expandingItems: {
      type: Array,
      default: () => []
    },
    expandMethod: {
      type: Function
    },
    itemToExpand: {
      type: Object,
      default: () => {}
    },
    isShowHidden: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selfExpand: false,
      loading: false,
    };
  },
  mounted() {
    this.itemToSearch && this.onSearchItem()
    this.keepExpand()
  },
  methods: {
    async onExpand(params) {
      try {
        this.loading = true;
        const isExpand = (params && params.selfExpand) || !this.selfExpand;
        if(!this.expandMethod) {
          this.selfExpand = isExpand;
          return;
        }
        await this.expandMethod({
          category: this.category,
          id: this.item[this.keyProp],
          expanding: isExpand,
          ...params
        })
        this.selfExpand = isExpand;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    onSearchItem() {
      if(!this.itemToSearch) {
        return;
      }
      if(this.item[this.keyProp] == this.itemToSearch[this.keyProp]) {
        this.$emit('searchDone');
      }
      this.itemToSearch.parent_structure
        && this.itemToSearch.parent_structure.findIndex(parent => parent.id == this.item[this.keyProp]) > -1
        && (!this.selfExpand || !this.isShowChildFolder)
        && this.onExpand({
          no_cache: true,
          noNeedLoadChildren: this.item[this.childrenProp] && this.item[this.childrenProp].length > 0,
          selfExpand: !this.isShowChildFolder ? true : null,
          ...this.itemToSearch.params,
        })
    },
    onSearchDone() {
      this.$emit('searchDone');
    },
    keepExpand() {
      if(!this.expandingItems)
        return
      this.expandingItems.forEach(i => {
        this.item[this.keyProp] == i
          && !this.selfExpand
          && this.onExpand({
            no_cache: true,
            noNeedLoadChildren: this.item[this.childrenProp] && this.item[this.childrenProp].length > 0,
          })
      });
    }
  },
  computed: {
    isShowChildFolder() {
      return (
        this.item[this.hasChildrenProp] &&
        this.item[this.childrenProp] &&
        this.item[this.childrenProp].length > 0
      );
    },
    isShowFolder() {
      if (!this.isChild) return true;
      return !this.item?.hidden || this.isShowHidden;
    },
  },
  watch: {
    itemToSearch(val) {
      val && this.onSearchItem()
    },
    itemToExpand(val) {
      if(val
        && val[this.keyProp] == this.item[this.keyProp]
        && val.category == this.category)
      {
        !val.params && (val.params = {})
        this.onExpand({...val.params, selfExpand: true })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recursive-item {
  padding-left: 24px;
  .content {
    position: relative;
    display: flex;
    align-items: center;
  }
  .expand-icon {
    z-index: 1;
    font-size: 16px;
    line-height: 35px;
    fill: var(--text-color);
    cursor: pointer;
    position: absolute;
    left: -25px;
  }
  .loading-icon {
    position: absolute;
    left: -24px;
  }
}
</style>
