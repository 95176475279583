import Vue from "vue";

import { GrowthBook } from "@growthbook/growthbook";
import { autoAttributesPlugin } from "@growthbook/growthbook/plugins";
import { GROWTHBOOK_API_HOST, GROWTHBOOK_CLIENT_KEY } from "@/config/index";

let instance;

export const getInstance = () => instance;

export const useGrowthBook = (options) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        growthBook: null,
      };
    },
    async created() {
      try {
        this.growthBook = new GrowthBook(options);

        // Wait for features to be available
        await this.growthBook.init({ streaming: true });
      } catch (e) {
        this.error = e;
        console.error(e);
      } finally {
      }
    },
    methods: {
      isOn(features) {
        if (!this.growthBook) return;
        return this.growthBook?.isOn(features);
      },
      getFeatureValue(features, fallBack) {
        if (!this.growthBook) return;
        return this.growthBook?.getFeatureValue(features, fallBack);
      },
      updateAttributes(attrs) {
        if (!this.growthBook) return;
        return this.growthBook?.updateAttributes(attrs);
      },
    },
  });
  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const GrowthBookPlugin = {
  install(Vue) {
    Vue.prototype.$growthBook = useGrowthBook({
      apiHost: GROWTHBOOK_API_HOST,
      clientKey: GROWTHBOOK_CLIENT_KEY,
      enableDevMode: true,
      attributes: {
        platform: "web",
      },
      trackingCallback: (experiment, result) => {
        // This is where you would send an event to your analytics provider
        console.log("Viewed Experiment", {
          experimentId: experiment.key,
          variationId: result.key,
        });
      },
      plugins: [autoAttributesPlugin()],
    });
  },
};
