import { render, staticRenderFns } from "./tagEmails.vue?vue&type=template&id=615c6e39&scoped=true"
import script from "./tagEmails.vue?vue&type=script&lang=js"
export * from "./tagEmails.vue?vue&type=script&lang=js"
import style0 from "./tagEmails.vue?vue&type=style&index=0&id=615c6e39&prod&lang=scss&scoped=true&rel=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615c6e39",
  null
  
)

export default component.exports