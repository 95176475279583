const auth0_domain = "auth.kpler.com";
const auth0_clientId = "13k11cGyWOTrxqznExq2l2cVyudhC3Oz";
const common_services_url = "https://api.kpler.com/mti-common";

const GROWTHBOOK_API_HOST = "https://cdn.growthbook.io";
const GROWTHBOOK_CLIENT_KEY = "sdk-bImeA2zmcHxwBh6L";

export {
  auth0_domain,
  auth0_clientId,
  common_services_url,
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
};
