import {
  ADD_LINES,
  ADD_SENDABLE_LINES,
  ADD_LINES_ALL,
  UPDATE_LINE_STATUS,
  UPDATE_LINE_COLOR,
  ADD_THREAD_EMAILS,
  ADD_BODY_TO_HISTORY_EMAIL,
  SET_NESTED_FOLDER,
  SET_DATA_RIGHT_CLICK,
  SET_FOLDER_TO_COPY,
  SET_DRAGED_MAIL,
  SET_DRAGGED_CONTACT,
  ADD_EMAIL_TO_READ,
  RESET_EMAIL_TO_READ,
  SET_LINECOUNT,
  REMOVE_RIGHT_CLICK_FOLDER,
  UPDATE_ONLINE_STATUS,
  START_LOADING_THREAD,
  FINISH_LOADING_THREAD,
  SET_CONFIGURED_TAGS,
  ADD_EXPANDING_NAV_ITEM,
  REMOVE_EXPANDING_NAV_ITEM,
  SET_HELP_CENTER_VERSION
} from "./mutation-type";
import storageConstant from '@/common/constants/local-storage.constant';

export default {
  [ADD_LINES](state, lines) {
    state.lines = lines;
  },
  [ADD_SENDABLE_LINES](state, lines) {
    state.sendableLines = lines;
  },
  [ADD_LINES_ALL](state, lines) {
    state.lines_all = lines;
  },
  [UPDATE_LINE_STATUS](state, updatedLine) {
    if (state.lines_all && state.lines_all.lines) {
      let line = state.lines_all.lines.find((l) => l.id == updatedLine.line_id);
      line && (line.status = updatedLine.status);
    }
  },
  [UPDATE_LINE_COLOR](state, updatedLine) {
    if (state.lines_all) {
      let full_line = (state.lines_all.full_lines || []).find((l) => l.id == updatedLine.line_id);
      full_line && (full_line.colour = updatedLine.colour);
      state.lines_all.full_lines && (state.lines_all.full_lines = [...state.lines_all.full_lines]);

      let line = (state.lines_all.lines || []).find((l) => l.id == updatedLine.line_id);
      line && (line.colour = updatedLine.colour);
      state.lines_all.lines && (state.lines_all.lines = [...state.lines_all.lines]);
    }
  },
  //mail history
  [ADD_BODY_TO_HISTORY_EMAIL](state, payload) {
    let {
      index,
      body,
      readers,
      archived,
      body_type,
      attachments
    } = payload;
    let email = state.threadEmails[index];
    email.body = body;
    email.body_type = body_type;
    email.archived = archived;
    email.attachments = attachments;
    email.readers = readers;
    state.threadEmails.splice(index, 1, email);
  },
  [ADD_THREAD_EMAILS](state, params) {
    if (!!params.reset) {
      state.threadEmails = params.emails;
    } else {
      state.threadEmails = [...params.emails, ...state.threadEmails] || [];
    }
    state.originalEmailId = params.originalEmailId;
    state.maxThreadEmail = params.maxThreadEmail;
    state.loadingThread = false;
  },
  [START_LOADING_THREAD](state, params) {
    state.loadingThread = true;
  },
  [FINISH_LOADING_THREAD](state, params) {
    state.loadingThread = false;
  },
  //next folder
  [SET_NESTED_FOLDER](state, folder) {
    state.nestedFolderSelected = folder;
  },
  [SET_FOLDER_TO_COPY](state, params) {
    state.folderToCopy = params.folder;
  },
  [SET_DATA_RIGHT_CLICK](state, params) {
    state.rightClickFolderData = params;
  },
  [REMOVE_RIGHT_CLICK_FOLDER](state) {
    state.rightClickFolderData = {};
  },
  [ADD_EMAIL_TO_READ](state, emailId) {
    state.emailTobeRead.push(emailId);
    localStorage.setItem(
      storageConstant.EMAIL_TO_BE_READ,
      JSON.stringify(state.emailTobeRead)
    );
  },
  [RESET_EMAIL_TO_READ](state) {
    state.emailTobeRead = [];
    localStorage.setItem(
      storageConstant.EMAIL_TO_BE_READ,
      JSON.stringify([])
    );
  },
  [SET_LINECOUNT](state, params) {
    state.lines_all.unread_count = params.allbox;
    if(!state.lines || state.lines.length === 0)
      return
    for (let item of state.lines) {
      const pLine = params.lines.find(l => l.id === item.id)
      pLine && (item.unread_count = pLine.unread_count)
    }
  },
  //drag
  [SET_DRAGED_MAIL](state, mails) {
    state.mailDragged = mails;
  },
  //---------------------------------ship/contact
  //contact dragged
  [SET_DRAGGED_CONTACT](state, contactDragged) {
    state.contactDragged = contactDragged;
  },
  [UPDATE_ONLINE_STATUS](state, data) {
    state.online = data;
  },
  [SET_CONFIGURED_TAGS](state, data) {
    state.configuredTags = data;
  },
  [ADD_EXPANDING_NAV_ITEM](state, itemName) {
    const index = state.expandingNavItems.findIndex(name => name == itemName)
    if(index == -1) {
      state.expandingNavItems.push(itemName)
      localStorage.setItem(storageConstant.EXPANDING_NAV_ITEMS, JSON.stringify(state.expandingNavItems))
    }
  },
  [REMOVE_EXPANDING_NAV_ITEM](state, itemName) {
    const index = state.expandingNavItems.findIndex(name => name == itemName)
    if(index > -1) {
      state.expandingNavItems.splice(index, 1)
      localStorage.setItem(storageConstant.EXPANDING_NAV_ITEMS, JSON.stringify(state.expandingNavItems))
    }
  },
  [SET_HELP_CENTER_VERSION](state, data) {
    Object.assign(state.helpCenter, data)
  }
};
