<template>
<div class="email-filing-wrapper">
  <FolderPicker
    :category="categories.ALL"
    :types="[folderTypes.NORMAL]"
    :value="value"
    @done="selectFolderDone"
  />
  <Modal
    class="email-filing-confirmation-dialog"
    v-model="confirmationShown"
    :title="(inSendMail ? 'This email will be filed' : `File ${emails.length} email${emails.length > 1 ? 's' : ''}`)
     + ` to ${selectedFolders.length} folder${selectedFolders.length > 1 ? 's' : ''}?`"
    :mask-closable="false"
    :loading="true"
    @on-ok="onConfirm()"
    @on-cancel="onCancel"
  >
    <div v-for="(folder, index) in selectedFolders" :key="index" class="folder-info" @click.stop="">
      <span class="folder-name text-ellipsis" :title="folder.name">{{folder.name}}</span>
      <span class="action" @click="onRemove(folder)">Remove</span>
    </div>
  </Modal>
</div>
</template>

<script>
import api from 'api';
import FolderPicker from '@/components/FolderPicker.vue';
import appConstant from '@/common/constants/app.constant';

export default {
  props: {
    value: {
      type: Array,
      required: false
    },
    emails: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedFolders: [],
      confirmationShown: false,
      folderTypes: appConstant.folderType,
      categories: appConstant.directory
    }
  },
  computed: {
    inSendMail() {
      return location.href.includes("sendMail");
    }
  },
  methods: {
    selectFolderDone(folders) {
      this.selectedFolders = folders;

      if (this.selectedFolders.length == 0 && !this.inSendMail)
        return;

      if(this.inSendMail) {
        this.$emit('ok', this.selectedFolders)
        return
      }

      this.confirmationShown = true;
    },
    onCancel() {
      this.confirmationShown = false
    },
    async onConfirm() {
      try {
        const email_ids = this.emails.map(e => e.id);
        const folder_ids = this.selectedFolders.map(f => f.id);
        this.$emit('done', this.selectedFolders);
  
        if (this.inSendMail) return;
  
        this.selectedFolders = [];
        if(email_ids.length == 0 || folder_ids.length == 0) return;
        folder_ids.length == 1 && email_ids.length == 1
          ? await api.add_email_to_folder({ folder_id: folder_ids[0], email_ids: email_ids })
          : await api.add_emails_to_folders(email_ids, folder_ids)
        this.$Message.success(`Added ${email_ids.length} email${email_ids.length > 1 ? 's' : ''} to ${folder_ids.length} folder${folder_ids.length > 1 ? 's' : ''} successfully!`);
      } catch (error) {
        console.log(error)
      } finally {
        this.confirmationShown = false;
      }
    },
    onRemove(folder) {
      const index = this.selectedFolders.findIndex(f => f.id == folder.id);
      index > -1 && this.selectedFolders.splice(index, 1);
    }
  },
  components: {
    FolderPicker
  }
}
</script>

<style lang="scss" scoped>
  .email-filing-wrapper {
    border-radius: 3px;
    min-width: 250px;
    max-width: 400px;
  }
</style>

<style lang="scss">
.email-filing-confirmation-dialog {
  .ivu-modal-body {
    max-height: 250px;
    overflow: auto;
    overflow: overlay;
  }
  .folder-info {
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    border-bottom: none;
    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }
    .folder-name {
      flex: 1;
      margin-right: 16px;
    }
    .action {
      cursor: pointer;
      color: var(--label-color);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
