const auth0_domain = "auth-tenant.dev.galil.io";
const auth0_clientId = "NwHAuPAnzP2TrBxOeYgobqEacJOCOuFP";
const common_services_url = "https://kong.dev.galil.io/mti-common";

const GROWTHBOOK_API_HOST = "https://cdn.growthbook.io";
const GROWTHBOOK_CLIENT_KEY =
  process.env.NODE_ENV === "development"
    ? "sdk-YT10HP4WtkXOqEAO"
    : "sdk-EvGWWpnZey7bmNtB";

export {
  auth0_domain,
  auth0_clientId,
  common_services_url,
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
};
